import { useCallback, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/app/hooks"
import {
  setOpenAlert,
  setLoadingDelete,
  setCloseAlert,
} from "../../../store/features/alert/alertSlice"
import { useMutation, useQuery } from "@apollo/client"
import {
  selectEventCategoryPagination,
  setQueryEventCategory,
  setPaginationEventCategory,
  selectEventCategoryQuery,
} from "../../../store/features/eventCategory/eventCategorySlice"
import { graphql } from "../../../gql"
import CustomTable from "../../Common/CustomTable/CustomTable"
import Loader from "../../Common/Loader/Loader"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { SortOrder, Place, QueryMode } from "../../../gql/graphql"
import { MRT_ColumnDef } from "material-react-table"
import { Pagination } from "../../../types/Pagination"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../store/features/menu/menuSlice"
import CustomImage from "../../Common/CustomImage/CustomImage"
import SwitchButton from "../../Common/SwitchButton/SwitchButton"
import { updateCachePlace } from "../../../caches/updateCachePlace"
import { havePermissions } from "../../../utils/permissions"

export const LIST_PLACES = graphql(`
  query Places(
    $where: PlaceWhereInput
    $aggregatePlaceWhere2: PlaceWhereInput
    $orderBy: [PlaceOrderByWithRelationAndSearchRelevanceInput!]
    $take: Int
    $skip: Int
  ) {
    places(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      name
      latitude
      longitude
      isPublish
      descriptions
      categories {
        id
        name
      }
      city {
        name_fr
        id
      }
      coverImage {
        url
        id
      }
    }
    aggregatePlace(where: $aggregatePlaceWhere2) {
      _count {
        _all
      }
    }
  }
`)

export const DELETE_PLACE = graphql(`
  mutation DeleteOnePlace($where: PlaceWhereUniqueInput!) {
    deleteOnePlace(where: $where) {
      id
      name
    }
  }
`)

export const UPDATE_PLACE = graphql(`
  mutation UpdateOnePlace(
    $data: PlaceUpdateInput!
    $where: PlaceWhereUniqueInput!
  ) {
    updateOnePlace(data: $data, where: $where) {
      id
      latitude
      longitude
      descriptions
      name
      isPublish
      categories {
        id
        name
      }
      city {
        id
        name_fr
      }
      coverImage {
        id
        url
      }
    }
  }
`)

export const DELETE_MANY = graphql(`
  mutation DeleteManyPlace($where: PlaceWhereInput) {
    deleteManyPlace(where: $where) {
      count
    }
  }
`)

const ListPlace = () => {
  const { page, size } = useAppSelector(selectEventCategoryPagination)
  const query = useAppSelector(selectEventCategoryQuery)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [ids, setIds] = useState<number[]>([])

  const handleSelected = (id: number) => {
    if (ids.includes(id)) {
      setIds((prev) => [...prev.filter((el) => el != id)])
    } else {
      setIds((prev) => [...prev, id])
    }
  }

  const [deleteMany] = useMutation(DELETE_MANY)

  const handleValidDeleteMany = () => {
    dispatch(setLoadingDelete(true))
    deleteMany({
      variables: {
        where: {
          id: {
            in: ids,
          },
        },
      },
      onCompleted: () => {
        refetch()
        dispatch(
          setOpenSnackbar({
            message: "La suppression a été fait avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        setIds([])
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
    })
  }

  const handleDeleteGroup = () => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDeleteMany(),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ces lieux ?",
        isLoading: false,
      }),
    )
  }

  const queryName = useCallback(() => {
    return query
      ? {
          OR: [{ name: { contains: query, mode: QueryMode.Insensitive } }],
        }
      : {}
  }, [query])

  const { loading, data, refetch } = useQuery(LIST_PLACES, {
    variables: {
      take: size,
      skip: page * size,
      orderBy: [{ isPublish: { sort: SortOrder.Desc } }],
      where: {
        ...queryName(),
      },
    },
  })

  const [deletePlace] = useMutation(DELETE_PLACE)
  const [updatePlace] = useMutation(UPDATE_PLACE)

  const handleTooglePublish = (id: number, isPublish: boolean) => {
    dispatch(setLoadingDelete(true))
    updatePlace({
      variables: {
        where: {
          id,
        },
        data: {
          isPublish: {
            set: !isPublish,
          },
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "La modification a été faite avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCachePlace({
          action: "update",
          cache,
          entryData: data?.updateOnePlace,
        })
      },
    })
  }

  const handleShowAlertPublish = (id: number, isPublish: boolean) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleTooglePublish(id, isPublish),
        message:
          "Êtes-vous vraiment sûr de vouloir modifier la statut de publication de ce lieu ?",
        isLoading: false,
      }),
    )
  }

  const columns = useMemo<MRT_ColumnDef<Place>[]>(
    () => [
      {
        accessorFn: (row) => (
          <>
            <SwitchButton
              checked={Boolean(row.isPublish)}
              handleChange={() => {
                if (havePermissions(["place.publish"])) {
                  handleShowAlertPublish(row.id, Boolean(row.isPublish))
                }
              }}
              id={row.id.toString()}
            />
          </>
        ),
        header: "Statut de publication",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => (
          <>
            <CustomImage
              url={row?.coverImage?.url || ""}
              style={{ width: "50px", height: "50px", borderRadius: "25px" }}
            />
          </>
        ),
        header: "Image de couverture",
        enableEditing: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
        enableClickToCopy: true,
      },
      {
        accessorKey: "name",
        header: "Nom",
      },
      {
        accessorFn: (row) =>
          row.categories && row.categories.length
            ? row.categories.map((el) => el.name).toString()
            : null,
        header: "Catégorie(s)",
      },
      {
        accessorFn: (row) => row?.city?.name_fr,
        header: "Ville",
      },
      {
        accessorKey: "latitude",
        header: "Latitude",
      },
      {
        accessorKey: "longitude",
        header: "Longitude",
      },
      {
        accessorKey: "descriptions",
        header: "Déscription",
      },
    ],
    [],
  )

  const handleChangePagination = (pagination: Pagination) =>
    dispatch(setPaginationEventCategory(pagination))

  const handleValidDelete = (id: number) => {
    dispatch(setLoadingDelete(true))
    deletePlace({
      variables: {
        where: {
          id,
        },
      },
      onCompleted: () => {
        dispatch(
          setOpenSnackbar({
            message: "Le lieu a été supprimé avec succès",
            status: "success",
          }),
        )
        dispatch(setLoadingDelete(false))
        dispatch(setCloseAlert())
      },
      onError: (err) => {
        const message = getErrorsAsString(err)
        dispatch(setCloseAlert())
        dispatch(setOpenSnackbar({ message }))
      },
      update: (cache, { data }) => {
        updateCachePlace({
          action: "delete",
          cache,
          entryData: data?.deleteOnePlace,
        })
      },
    })
  }

  const handleOpenDeleteDialog = (id: number) => {
    dispatch(
      setOpenAlert({
        handleValid: () => handleValidDelete(id),
        message: "Êtes-vous vraiment sûr de vouloir supprimer ce lieu ?",
        isLoading: false,
      }),
    )
  }

  const onChangeSearchValue = (value: string) =>
    dispatch(setQueryEventCategory(value))

  const handleEdit = (id: number) => {
    dispatch(setTitle("Modifier un lieu"))
    navigate(`/place/edit/${id}`)
  }

  if (loading && !query) return <Loader />

  return (
    <CustomTable
      columns={columns}
      data={data?.places || []}
      lableAddNew="Créer un nouveau lieu"
      rootLisName={"/place/add"}
      isLoading={loading}
      showProgressBars={loading}
      rowCount={data?.aggregatePlace._count?._all || 0}
      handleChangePagination={handleChangePagination}
      pageIndex={page}
      pageSize={size}
      searchValue={query}
      onChangeSearchValue={onChangeSearchValue}
      handleDelete={handleOpenDeleteDialog}
      handleEdit={handleEdit}
      handleSelected={handleSelected}
      handleDeleteGroup={handleDeleteGroup}
      selectedIds={ids}
      permissionChange="place.change"
      permissionCreate="place.create"
      permissionDelete="place.delete"
      permissionView="place.view"
      handleSelecteds={setIds}
    />
  )
}

export default ListPlace

import { gql } from "@apollo/client"

export const COMMISSION_FRAGMENT = gql(`
  fragment CommissionsFragment on Commissions {
    id
    frequency
    price
    type
    createdAt
    updatedAt
    countries {
      id
      name_fr
      currency
    }
  }
`)

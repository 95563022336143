import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../store/app/hooks"
import { setOpenSnackbar } from "../../../store/features/snackbar/snackbarSlice"
import { getErrorsAsString } from "../../../utils/getErrorsAsString"
import { graphql } from "../../../gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { updateCacheCommission } from "../../../caches/updateCacheCommission"
import Loader from "../../Common/Loader/Loader"
import { CommissionType, Frequency } from "../../../gql/graphql"
import { InfinityListItem } from "../../../types/InfinityListItem"
import CustomListCountry from "../../Common/CustomListCountry/CustomListCountry"

const GET_COMMISSION = graphql(`
  query Commission($where: CommissionWhereUniqueInput!) {
    commission(where: $where) {
      id
      frequency
      price
      type
      createdAt
      updatedAt
      countries {
        id
        name_fr
        currency
      }
    }
  }
`)

const UPDATE_COMMISSION = graphql(`
  mutation UpdateCommission(
    $type: CommissionType!
    $commissionId: Int!
    $price: Float!
    $frequency: Frequency!
    $countryIds: [Int!]
  ) {
    updateCommission(
      type: $type
      commissionId: $commissionId
      price: $price
      frequency: $frequency
      countryIds: $countryIds
    ) {
      id
      frequency
      price
      type
      createdAt
      updatedAt
      countries {
        id
        name_fr
        currency
      }
    }
  }
`)

const CREATE_COMMISSION = graphql(`
  mutation CreateCommission(
    $type: CommissionType!
    $price: Float!
    $frequency: Frequency!
    $countryIds: [Int!]
  ) {
    createCommission(
      type: $type
      price: $price
      frequency: $frequency
      countryIds: $countryIds
    ) {
      id
      frequency
      price
      type
      createdAt
      updatedAt
      countries {
        id
        name_fr
        currency
      }
    }
  }
`)

export default function AddCommission() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [frequency, setFrequency] = useState(Frequency.Ponctuel)
  const [commission, setCommission] = useState({
    price: "",
  })
  const [country, setCountry] = useState({ label: "", value: "" })
  const [create, { loading }] = useMutation(CREATE_COMMISSION)
  const [update, { loading: loadingUpdate }] = useMutation(UPDATE_COMMISSION)

  const params = useParams()

  const id = params.id ? parseInt(params.id, 10) : 0

  const handleInputChange = (event: any) => {
    setCommission({
      ...commission,
      [event.target.name]: event.target.value,
    })
  }

  const [getCommission, { loading: loadingInit }] = useLazyQuery(
    GET_COMMISSION,
    {
      onCompleted(data) {
        const commission = data?.commission
        setCommission({
          price: commission?.price?.toString() || "",
        })
        setFrequency(commission?.frequency || Frequency.Ponctuel)
        if (commission?.countries?.length) {
          const newCountry: InfinityListItem = {
            value: commission?.countries[0]?.id.toString() || "",
            label: commission?.countries[0]?.name_fr || "",
          }
          setCountry(newCountry)
        }
      },
    },
  )

  const handleCreate = () => {
    create({
      variables: {
        frequency: frequency,
        price: Number(commission.price),
        type: CommissionType.Create,
        countryIds: [Number(country.value)],
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
      },
      onCompleted: () => {
        navigate("/commission/list")
      },
      update: (cache, { data }) => {
        updateCacheCommission({
          action: "add",
          cache,
          entryData: data?.createCommission,
        })
      },
    })
  }

  const handleUpdate = () => {
    update({
      variables: {
        frequency: frequency,
        price: Number(commission.price),
        type: CommissionType.Create,
        commissionId: id,
        countryIds: [Number(country.value)],
      },
      onError: (error) => {
        const message = getErrorsAsString(error)
        dispatch(setOpenSnackbar({ message }))
      },
      onCompleted: () => {
        navigate("/commission/list")
      },
      update: (cache, { data }) => {
        updateCacheCommission({
          action: "update",
          cache,
          entryData: data?.updateCommission,
        })
      },
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (id) {
      handleUpdate()
    } else {
      handleCreate()
    }
  }

  const getCountry = (country: InfinityListItem) => {
    setCountry(country)
  }

  useEffect(() => {
    if (id) {
      getCommission({
        variables: {
          where: {
            id,
          },
        },
      })
    }
  }, [id])

  const handleAddFrequency = (frequency: Frequency) => {
    setFrequency(frequency)
  }

  if (loadingInit) return <Loader />

  return (
    <Grid container spacing={3}>
      <Grid item xs={1} md={1} lg={2} />
      <Grid item xs={12} md={12} lg={8}>
        <Paper
          sx={{
            px: 2,
            py: 3,
            position: "relative",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Typography sx={{ textAlign: "center" }} variant="h1">
                  Ajouter une commission fixe par pays
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <CustomListCountry
                  countryDefault={country}
                  getCountry={getCountry}
                  required={true}
                  multiple={false}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="price"
                  label="Commission fixe selon la devise de pays"
                  fullWidth
                  type="number"
                  variant="outlined"
                  value={commission.price}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography>Fréquence</Typography>
                <Box mt={2}>
                  <Chip
                    label="Ponctuelle"
                    onClick={() => handleAddFrequency(Frequency.Ponctuel)}
                    sx={{ ml: 1 }}
                    color={
                      frequency === Frequency.Ponctuel ? "success" : "primary"
                    }
                  />
                  <Chip
                    label="Faible(5 jours)"
                    onClick={() => handleAddFrequency(Frequency.Once)}
                    sx={{ ml: 1 }}
                    color={frequency === Frequency.Once ? "success" : "primary"}
                  />
                  <Chip
                    label="Moyenne(15 jours)"
                    onClick={() => handleAddFrequency(Frequency.Medium)}
                    sx={{ ml: 1 }}
                    color={
                      frequency === Frequency.Medium ? "success" : "primary"
                    }
                  />
                  <Chip
                    label="Élevé(30 jours)"
                    onClick={() => handleAddFrequency(Frequency.High)}
                    sx={{ ml: 1 }}
                    color={frequency === Frequency.High ? "success" : "primary"}
                  />
                </Box>
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"center"}
                item
                xs={12}
                lg={12}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading || loadingUpdate}
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={1} md={1} lg={2} />
    </Grid>
  )
}
